import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Button, Row } from 'react-bootstrap';

import './index.scss';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';

const BCMIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page bcm '} game="bcm">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Black Clover Mobile wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Black Clover Mobile. Check our guides, tier
          lists and reviews for characters available in Global, KR and JPN
          versions of game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Play Black Clover Mobile on PC" />
      <div className="banner bcm">
        <div className="cta">
          <h4>Play Black Clover Mobile on PC</h4>
          <OutboundLink
            href="https://www.ldplayer.net/games/black-clover-m-on-pc.html?n=82774712#utm_source=aff&utm_medium=aff&utm_campaign=aff82774712"
            target="_blank"
          >
            <Button variant="primary">Play now on LDPlayer</Button>
          </OutboundLink>
        </div>
      </div>
      <SectionHeader title="Shortcuts" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/black-clover/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/blackclover/categories/cat_about.png"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/black-clover/guides/reroll"
          image={
            <StaticImage
              src="../../images/blackclover/categories/cat_reroll.png"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Beginner guide"
          link="/black-clover/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/blackclover/categories/cat_beginner.png"
              alt="Beginner guide"
            />
          }
        />
        <CategoryCard
          title="Basic Combat Guide"
          link="/black-clover/guides/basic-combat-guide"
          image={
            <StaticImage
              src="../../images/blackclover/categories/cat_basiccombat.png"
              alt="Basic Combat Guide"
            />
          }
        />
        <CategoryCard
          title="Gear Sets"
          link="/black-clover/guides/gear-sets"
          image={
            <StaticImage
              src="../../images/blackclover/categories/cat_sets.png"
              alt="Best gear sets"
            />
          }
        />
        <CategoryCard
          title="Best teams for farm gear"
          link="/black-clover/guides/best-teams-to-farm-gear"
          image={
            <StaticImage
              src="../../images/blackclover/categories/cat_gear.png"
              alt="Best teams for farm gear"
            />
          }
        />
        <CategoryCard
          title="Great lower rarity Skill Pages"
          link="/black-clover/guides/great-skill-pages"
          image={
            <StaticImage
              src="../../images/blackclover/categories/cat_skillpages.png"
              alt="Great Skill Pages"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default BCMIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Black Clover Mobile Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Black Clover Mobile. Check our guides, tier lists and reviews for characters available in Global, KR and JPN versions of game."
    game="bcm"
  />
);
